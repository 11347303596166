.home-page .carousel-indicators [data-bs-target]{
    color:transparent;
}

.home-carousel{
    width: 40%;
    margin-left: 30%;
    margin-bottom: 3rem;
}

@media only screen and (max-width: 991px) {
    /* your css of 1024 px screen size */
    .home-carousel{
        width: 100%;
        margin-left: 0%;
    }
}

.home-news-title{
    font-size: 1.25rem;
}

.home-news-text{
    margin-right: -0.5rem;
    padding-right: 0.5rem;
}




.home-carousel .carousel-item{
    max-height: 50vh;
}

.home-page{
    font-size: 1.25rem;
}

.home-cards{
    max-width: 75vw;
    margin-left: 12.5vw;
}

.home-card-img{
    height: 3.5rem;
}

.home-news-link{
    color:black;
    text-decoration: none;
}

.home-news-tab .nav-link{
    color: black;
    font-size: 1.25rem;
    height: 100%;
    text-align: center;
}


.home-news-tab .nav-link:hover {
    background-color: #01726b70;
}

.home-news-tab .nav-link.active {
    color: #ffffff;
    background-color: #01726bcc;
}

.home-news-pane{
    height: 25rem;
    overflow-x: hidden;
    overflow-y: auto;
}


/* width */
.home-news-pane::-webkit-scrollbar {
    left: -5rem;
    width: 5px;
}

/* Track */
.home-news-pane::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.home-news-pane::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.home-news-text::-webkit-scrollbar-thumb:hover {
  background: #555;
}