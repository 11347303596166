.header-nav{
  min-height: 4rem;
  background-color: #01726b;
  font-size: 1.1rem;
}

.header-nav .nav-link{
  color:rgba(255, 255, 255, 0.8) !important;
}

.header-nav .nav-link:hover{
  color:rgba(255, 255, 255, 1) !important;
}

.header-logo{
  height: 3rem;
  max-width: 100%;
}

.header-title{
  max-width: 50vw;
  width: 25rem;
  padding: 0;
  text-align: center;
}

.header-lang-icon{
  height:1rem;
  margin-bottom: 0.2rem;
}