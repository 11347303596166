.footer-nav{
  min-height: 10rem;
  padding-top:3rem;
  padding-bottom:1rem;
  color:white;
  background-color: #01726b;
}

.footer-title{
  font-size: medium; 
}

.footer-link{
  color:white;
}