.header-nav{
  min-height: 4rem;
  background-color: #01726b;
  font-size: 1.1rem;
}

.header-nav .nav-link{
  color:rgba(255, 255, 255, 0.8) !important;
}

.header-nav .nav-link:hover{
  color:rgba(255, 255, 255, 1) !important;
}

.header-logo{
  height: 3rem;
  max-width: 100%;
}

.header-title{
  max-width: 50vw;
  width: 25rem;
  padding: 0;
  text-align: center;
}

.header-lang-icon{
  height:1rem;
  margin-bottom: 0.2rem;
}
.home-page .carousel-indicators [data-bs-target]{
    color:transparent;
}

.home-carousel{
    width: 40%;
    margin-left: 30%;
    margin-bottom: 3rem;
}

@media only screen and (max-width: 991px) {
    /* your css of 1024 px screen size */
    .home-carousel{
        width: 100%;
        margin-left: 0%;
    }
}

.home-news-title{
    font-size: 1.25rem;
}

.home-news-text{
    margin-right: -0.5rem;
    padding-right: 0.5rem;
}




.home-carousel .carousel-item{
    max-height: 50vh;
}

.home-page{
    font-size: 1.25rem;
}

.home-cards{
    max-width: 75vw;
    margin-left: 12.5vw;
}

.home-card-img{
    height: 3.5rem;
}

.home-news-link{
    color:black;
    text-decoration: none;
}

.home-news-tab .nav-link{
    color: black;
    font-size: 1.25rem;
    height: 100%;
    text-align: center;
}


.home-news-tab .nav-link:hover {
    background-color: #01726b70;
}

.home-news-tab .nav-link.active {
    color: #ffffff;
    background-color: #01726bcc;
}

.home-news-pane{
    height: 25rem;
    overflow-x: hidden;
    overflow-y: auto;
}


/* width */
.home-news-pane::-webkit-scrollbar {
    left: -5rem;
    width: 5px;
}

/* Track */
.home-news-pane::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.home-news-pane::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.home-news-text::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.about-page{
    font-size: 1.25rem;
}

.about-research-image{
    height: 5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
/* Small devices (landscape phones, 576px and up)*/
/* @media (min-width: 576px) { */
@media (max-width: 767px) {
    .w-sm-100 {
    width:100%!important;
    }
    .w-sm-75 {
    width:75%!important;
    }
    .w-sm-50 {
    width:50%!important;
    }
    .w-sm-25 {
    width:25%!important;
    }
    .h-sm-100 {
    height:100%!important;
    }
    .h-sm-75 {
    height:75%!important;
    }
    .h-sm-50 {
    height:50%!important;
    }
    .h-sm-25 {
    height:25%!important;
    }
}
    
    /* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .w-md-100 {
    width:100%!important;
    }
    .w-md-75 {
    width:75%!important;
    }
    .w-md-50 {
    width:50%!important;
    }
    .w-md-25 {
    width:25%!important;
    }
    .h-md-100 {
    height:100%!important;
    }
    .h-md-75 {
    height:75%!important;
    }
    .h-md-50 {
    height:50%!important;
    }
    .h-md-25 {
    height:25%!important;
    }
}
    
    /* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .w-lg-100 {
    width:100%!important;
    }
    .w-lg-75 {
    width:75%!important;
    }
    .w-lg-50 {
    width:50%!important;
    }
    .w-lg-25 {
    width:25%!important;
    }
    .h-lg-100 {
    height:100%!important;
    }
    .h-lg-75 {
    height:75%!important;
    }
    .h-lg-50 {
    height:50%!important;
    }
    .h-lg-25 {
    height:25%!important;
    }
}
    
    /* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .w-xl-100 {
    width:100%!important;
    }
    .w-xl-75 {
    width:75%!important;
    }
    .w-xl-50 {
    width:50%!important;
    }
    .w-xl-25 {
    width:25%!important;
    }
    .h-xl-100 {
    height:100%!important;
    }
    .h-xl-75 {
    height:75%!important;
    }
    .h-xl-50 {
    height:50%!important;
    }
    .h-xl-25 {
    height:25%!important;
    }
}


.album-link{
     color:black;
    text-decoration: none;
}
    
.album-tab .nav-link{
    color: black;
    font-size: 1.25rem;
    height: 100%;
    text-align: center;
}

.album-photo-row{
    height: 68vh;
}

.album-photo{
    max-height: 65vh;
    max-width: 100%;
}

.album-tab .nav-link:hover {
    background-color: #01726b70;
}

.album-tab .nav-link.active {
    color: #ffffff;
    background-color: #01726bcc;
}

.album-thumbnail{
    width: 10vw;
    height: 7.5vw;
    margin-right: 1rem;
    box-shadow: 5px 5px 3px rgb(137, 137, 137);
}
.member-detail{
    font-size: 1.1rem;
}
.members-page{
    font-size: 1.25rem;
}

.members-tabs .nav-link {
    color: black;
    font-size: 1.25rem;
    width: 20rem;
    max-width: 30vw;
    height: 100%;
    text-align: center;
}

.members-tabs-zh .nav-link{
    font-size: 1.5rem;
}

.members-tabs .nav-link:hover {
    background-color: #01726b70;
}

.members-tabs .nav-link.active {
    color: #ffffff;
    background-color: #01726bcc;
}

.members-grad-btn{
    background-color: #01726b;
    border: 0px;
    width:10rem;
}

.members-grad-btn:hover{
    background-color: #01726b70;
}

.members-grad-btn:focus{
    background-color: #01726b;
}



@media only screen and (min-width: 992px) {
    /* your css of 1024 px screen size */
    .members-list{
        width: 85%;
        margin-left: 7.5%;
    }

    .members-tabs .nav-link {
        max-width: 20vw;
    }
}

@media only screen and (min-width: 1200px) {
    /* your css of 1024 px screen size */
    .members-list{
        width: 75%;
        margin-left: 12.5%;
    }
}
.app-content{
  min-height: 90vh;
  padding: 1rem;
}
.footer-nav{
  min-height: 10rem;
  padding-top:3rem;
  padding-bottom:1rem;
  color:white;
  background-color: #01726b;
}

.footer-title{
  font-size: medium; 
}

.footer-link{
  color:white;
}
