.members-page{
    font-size: 1.25rem;
}

.members-tabs .nav-link {
    color: black;
    font-size: 1.25rem;
    width: 20rem;
    max-width: 30vw;
    height: 100%;
    text-align: center;
}

.members-tabs-zh .nav-link{
    font-size: 1.5rem;
}

.members-tabs .nav-link:hover {
    background-color: #01726b70;
}

.members-tabs .nav-link.active {
    color: #ffffff;
    background-color: #01726bcc;
}

.members-grad-btn{
    background-color: #01726b;
    border: 0px;
    width:10rem;
}

.members-grad-btn:hover{
    background-color: #01726b70;
}

.members-grad-btn:focus{
    background-color: #01726b;
}



@media only screen and (min-width: 992px) {
    /* your css of 1024 px screen size */
    .members-list{
        width: 85%;
        margin-left: 7.5%;
    }

    .members-tabs .nav-link {
        max-width: 20vw;
    }
}

@media only screen and (min-width: 1200px) {
    /* your css of 1024 px screen size */
    .members-list{
        width: 75%;
        margin-left: 12.5%;
    }
}